<template>
	<a-tree-select
		v-model:value="departmentValue"
		:treeDefaultExpandAll="true"
		placeholder="请选择部门"
		style="min-width: 200px"
		:tree-line="true"
		allowClear
		:tree-data="options"
		tree-node-filter-prop="title"
		@change="handleChange"
	>
	</a-tree-select>
</template>
<script setup>
import { ref, defineProps, defineEmits, onActivated, watch } from "vue"
const props = defineProps({
	diasbled: Boolean,
	defaultId: {
		type: String,
		default: "",
	},
})

const emits = defineEmits(["changeValue"])
const departmentValue = ref("")
import axios from "@/common/axios"
import api from "@/common/api/api"
import { useStore } from "vuex"

const options = ref([])
const store = useStore()
onActivated(() => {
	console.log("departmentValue", props.defaultId)
	departmentValue.value = ""
	departmentValue.value = props.defaultId ? props.defaultId : ""
	getList()
})
watch(
	() => props.defaultId,
	(value) => {
		departmentValue.value = value ? value : ""
	}
)
const treeParams = ref({
	level: "",
	name: "", // 名称
	parentId: 0, // 父级ID
	status: true, // 状态1 可用 0禁用
	tree: true, //存储请求时的pagesize
})
const handleChange = (value) => {
	emits("changeValue", value)
}

const getList = (value) => {
	// console.log(store.warehouseList);
	console.log(value)
	let data = {
		merchantId: store.getters["user/merchantId"],
		...treeParams.value,
	}
	axios.post(api.departmentList, data).then((res) => {
		console.log(res)
		options.value = res
		xuLieHua(options.value)
	})
}
const xuLieHua = (value) => {
	value.forEach((item) => {
		item.value = String(item.id)
		item.label = item.name
		if (item.children?.length > 0) {
			xuLieHua(item.children)
		}
	})
}
</script>
