<template>
	<a-select
		v-model:value="warehouseCodes"
		allow-clear
		:mode="props.mode"
		:class="props.class ? '' : 'inputSearch'"
		show-search
		placeholder="请选择仓库"
		:default-active-first-option="false"
		:show-arrow="false"
		:filter-option="false"
		:not-found-content="null"
		:options="options"
		style="width: 183px"
		:disabled="props.diasbled"
		@change="handleChange"
	/>
</template>
<script setup>
import { ref, onMounted, defineProps, defineEmits } from "vue"
const props = defineProps({
	diasbled: Boolean,
	class: {
		type: String,
		default: "",
	},
	mode: {
		type: String,
		default: "",
	},
	first: {
		type: Boolean,
		default: false,
	},
})

const emits = defineEmits(["changeValue"])
const warehouseCodes = ref(null)
import axios from "@/common/axios"
import api from "@/common/api/api"
import { useStore } from "vuex"

const options = ref([])
const warehouseList = ref([])
const store = useStore()
onMounted(() => {
	if (props.mode == "multiple") {
		warehouseCodes.value = []
	}
	getList()
})

const handleChange = (value) => {
	let params = null
	if (props.mode == "multiple") {
		params = [...value]
	} else {
		let data = warehouseList.value.find((item) => item.uniqueCode === value)
		params = data
	}
	emits("changeValue", params)
}

const getList = () => {
	let data = {
		merchantCode: store.getters["user/merchantCode"],
		status: 1,
	}
	axios.post(api.queryWarehousePage, data).then((res) => {
		console.log(res)
		if (res) {
			warehouseList.value = res.list
			let arr = []
			res.list.forEach((item) => {
				arr.push({
					label: item.name,
					value: item.uniqueCode,
				})
			})
			options.value = arr
		} else {
			options.value = []
		}
	})
}
</script>
