<template>
	<a-card class="guest-container">
		<template #title>
			<div class="header">
				<a-button type="primary" @click="handleAdd">新增用户</a-button>

				<a-form layout="inline" style="margin-top: 10px">
					<a-form-item label="用户名">
						<a-input v-model:value="userName" @keyup.enter="getDataList(1)" placeholder="请输入用户名" />
					</a-form-item>
					<a-form-item label="手机号">
						<a-input v-model:value="mobile" placeholder="请输入手机号" />
					</a-form-item>
					<a-form-item label="仓库">
						<SelectWarehouse @change-value="setWarehouse" />
					</a-form-item>
					<a-form-item label="部门">
						<SelectDepartment @change-value="setDepartment" />
					</a-form-item>
					<a-form-item>
						<a-button type="primary" @keyup.enter="getDataList(1)" @click="getDataList(1)"> 查询 </a-button>
						<a-button style="margin-left: 10px" @click="reset">重置</a-button>
					</a-form-item>
				</a-form>
			</div>
		</template>
		<a-table :loading="loading" :columns="columns" :data-source="tableData" :scroll="{ x: 1200, y: 600 }" :pagination="false">
			<template #bodyCell="{ column, record }">
				<template v-if="column.key === 'status'">
					<el-popconfirm
						confirm-button-text="确认"
						cancel-button-text="取消"
						title="确认修改应用状态吗"
						@confirm="confirmSwitch(record)"
						@cancel="cancelSwitch(record)"
					>
						<template #reference>
							<el-switch @click="clickSwitch(record)" v-model="record.status" />
						</template>
					</el-popconfirm>
				</template>
				<template v-if="column.key === 'aciton'">
					<span>
						<a @click="handleEdit(record)">编辑</a>
						<a style="margin: 0 10px" @click="changeDiagle('info', record)">详情</a>
						<a @click="authAccount(record)">授权</a>
					</span>
				</template>
			</template>
		</a-table>
		<a-pagination
			style="float: right; margin-top: 10px"
			v-model:current="page_no"
			v-model:pageSize="page_size"
			show-size-changer
			:total="total"
			:show-total="(total) => `总计 ${total} 条`"
			@change="handleChangePage"
		>
			<template v-slot:buildOptionText="props">
				<span>{{ props.value }}条/页</span>
			</template>
		</a-pagination>
	</a-card>
	<a-drawer width="640" placement="right" v-model:visible="dialogVisible" @close="changeDiagle('close')">
		<p class="pStyleTitle">用户详情({{ appInfo.userName }})</p>
		<div class="pStyle">
			<IdaasDescription title="用户名称" :text="appInfo.userName" />
			<IdaasDescription title="姓名" :text="appInfo.realName" />
			<IdaasDescription title="手机号" :text="appInfo.mobile" />
			<IdaasDescription title="邮箱" :text="appInfo.email" />
			<IdaasDescription title="状态" :text="appInfo.status === 0 ? '禁用' : '启用'" />
			<IdaasDescription title="创建人" :text="appInfo.createdUser" />
			<IdaasDescription title="创建时间" :text="appInfo.createdAt" />
			<IdaasDescription title="修改人" :text="appInfo.updatedUser" />
			<IdaasDescription title="修改时间" :text="appInfo.updatedAt" />
			<IdaasDescription title="角色" :text="appInfo.roleInfo" />
			<IdaasDescription title="业务组" :text="appInfo.departmentTxt" />
		</div>
	</a-drawer>
</template>

<script>
import { reactive, ref, toRefs, onActivated } from "vue"
// import axios from "@/common/axios";
import { useRouter, useRoute } from "vue-router"
import axios from "@/common/axios"
import api from "@/common/api/api"
import { useStore } from "vuex"
import IdaasDescription from "@/components/IdaasDescription"
import SelectWarehouse from "@/components/SelectWarehouse"
import SelectDepartment from "@/components/SelectDepartment"
import { message } from "ant-design-vue"
export default {
	name: "User",
	components: {
		IdaasDescription,
		SelectWarehouse,
		SelectDepartment,
	},
	setup() {
		const multipleTable = ref(null)
		const router = useRouter()
		const store = useStore()
		const route = useRoute()
		const state = reactive({
			loading: false,
			appName: "",
			tableData: [], // 数据列表
			selectList: [], // 选中项
			columns: [
				{
					title: "用户名称",
					dataIndex: "userName",
					key: "userName",
					width: 120,
				},
				{
					title: "部门",
					dataIndex: "department",
					key: "department",
					width: 120,
				},
				{
					title: "手机号",
					dataIndex: "mobile",
					key: "mobile",
					width: 120,
				},

				{
					title: "创建人",
					dataIndex: "createdUser",
					key: "createdUser",
					width: 120,
				},
				{
					title: "修改人",
					dataIndex: "updatedUser",
					key: "updatedUser",
					width: 120,
				},
				{
					title: "创建时间",
					dataIndex: "createdAt",
					key: "createdAt",
					width: 150,
				},
				{
					title: "修改时间",
					dataIndex: "updatedAt",
					key: "updatedAt",
					width: 150,
				},
				{
					title: "状态",
					dataIndex: "status",
					key: "status",
					width: 100,
				},
				{
					title: "操作",
					key: "aciton",
					width: 150,
					fixed: "right",
				},
			],
		})
		onActivated(() => {
			if (route.query.page) {
				params.page_no = Number(route.query.page.split("-")[0])
				params.page_size = Number(route.query.page.split("-")[1])
			}
			getDataList()
		})
		const params = reactive({
			mobile: "",
			userName: "",
			merchantId: store.getters["user/merchantId"],
			total: 0, // 总条数
			page_no: 1, // 当前页
			page_size: 10, // 分页大小
			old_page_size: 10, // 分页大小
			warehouseCode: "",
			wmsAppCode: "",
			departmentId: "",
		})
		const reset = () => {
			params.userName = ""
			params.mobile = ""
			params.wmsAppCode = ""
			params.departmentId = ""
			params.total = 0
			params.page_no = 1
			params.page_size = 10
			params.old_page_size = 10
			getDataList()
		}
		const setWarehouse = (value) => {
			console.log(value)
			params.warehouseCode = value?.code
			params.wmsAppCode = value?.wmsAppCode
		}
		const setDepartment = (value) => {
			console.log(value)
			params.departmentId = value
		}
		// 获取列表
		const getDataList = (pageNo) => {
			state.loading = true
			pageNo && (params.page_no = pageNo)
			const data = {
				...params,
			}
			console.log(data)
			axios
				.post(api.getUserList, data)
				.then((res) => {
					console.log("getUserList", res)
					res.list.forEach((item) => {
						item.status = item.status == 1 ? true : false
					})
					state.tableData = res.list
					state.tableData.forEach((item) => {
						if (item.departmentList) {
							item.department = ""
							item.departmentList.forEach((name) => {
								item.department = item.department ? item.department + "/" + name.department.name : item.department + name.department.name
							})
						}
					})
					params.total = res.total
					// params.page_no = res.page_no;
					// params.page_size = res.page_size;
					// params.old_page_size = res.page_size;
					state.loading = false
				})
				.catch((err) => {
					console.log(err)
					state.loading = false
				})
		}
		// 当前页
		const handleChangePage = (page, pageSize) => {
			params.page_no = page
			params.page_size = pageSize
			getDataList()
		}
		// 新增
		const handleAdd = () => {
			router.push({
				name: "addAccountManage",
				// query: {
				//   page: params.page_no + "-" + params.page_size,
				// },
			})
		}
		// 编辑
		const handleEdit = (id) => {
			router.push({
				name: "addAccountManage",
				query: {
					id: JSON.stringify(id),
					page: params.page_no + "-" + params.page_size,
				},
			})
		}
		// 详情模板
		const diagleDate = reactive({
			dialogVisible: false,
			appInfo: {},
		})
		const changeDiagle = (type, value) => {
			if (type === "info") {
				console.log(value)
				value
				diagleDate.dialogVisible = true
				axios.post(api.userInfo, { userId: value.id }).then((res) => {
					console.log(res)
					const arr = []
					res.user.roleList.forEach((item) => {
						console.log(item.role.authSysCode, item.role.name)
						if (arr.length) {
							arr.forEach((otem) => {
								if (item.role.authSysCode == otem.authSysCode) {
									otem.child.push({
										name: item.role.name,
									})
								} else {
									arr.push({
										authSysCode: item.role.authSysCode,
										child: [
											{
												name: item.role.name,
											},
										],
									})
								}
							})
						} else {
							arr.push({
								authSysCode: item.role.authSysCode,
								child: [
									{
										name: item.role.name,
									},
								],
							})
						}

						// res.user.roleInfo=   res.user.roleInfo? res.user.roleInfo+','+item.role.authSysCode+'-'+item.role.name:item.role.authSysCode+'-'+item.role.name
					})
					console.log(arr)
					arr.forEach((item) => {
						res.user.roleInfo = res.user.roleInfo ? res.user.roleInfo + item.authSysCode + ":" : item.authSysCode + ":"
						item.child.forEach((otem, index) => {
							console.log(otem)
							res.user.roleInfo = res.user.roleInfo + (index + 1) + ":" + otem.name
						})
						res.user.roleInfo = res.user.roleInfo + "\n"
					})
					let departmentTxt = ""
					res.user.departmentList.forEach((item) => {
						if (item.departmentType == 2) {
							departmentTxt = departmentTxt ? departmentTxt + "、" + item.department.name : item.department.name
						}
					})
					res.user.departmentTxt = departmentTxt
					diagleDate.appInfo = res.user
				})
			} else {
				diagleDate.dialogVisible = false
			}
		}
		const confirmSwitch = (value) => {
			state.tableData.forEach((item) => {
				if (item.userName == value.userName) {
					item.status = !value.status
				}
			})

			let data = {
				user: { ...value },
			}

			axios.post(api.saveUser, data).then(() => {
				message.success("修改成功")
				getDataList()
			})
		}
		const cancelSwitch = () => {
			// getDataList();
		}
		const clickSwitch = (value) => {
			console.log(value)
			console.log(state.tableData)
			state.tableData.forEach((item) => {
				if (item.userName == value.userName) {
					item.status = !value.status
				}
			})
		}
		const authAccount = (value) => {
			console.log(value)
			router.push({
				name: "authAccount",
				query: {
					userId: value.id,
					userName: value.realName,
					page: params.page_no + "-" + params.page_size,
				},
			})
		}
		return {
			...toRefs(state),
			...toRefs(diagleDate),
			...toRefs(params),
			multipleTable,
			getDataList,
			setWarehouse,
			setDepartment,
			handleAdd,
			handleEdit,
			authAccount,
			handleChangePage,
			changeDiagle,
			cancelSwitch,
			clickSwitch,
			confirmSwitch,
			reset,
		}
	},
}
</script>
<style lang="scss" scoped>
.guest-container {
	min-height: 100%;
}
.el-card.is-always-shadow {
	min-height: 100% !important;
}
.marginInfoList {
	margin: 10px 0;
	color: #6f7071;
	font-size: 14px;
	.infoLabel {
		text-align: center;
	}
	.infoValue {
		text-align: left;
	}
}
.pStyle {
	font-size: 12px;
	color: #657180;
}
.pStyleTitle {
	font-size: 18px;
	font-weight: 900;
}
</style>
